import React, { useState, useEffect } from 'react';
import NavBar from './headerComponents/navBar';
import 'semantic-ui-css/semantic.min.css';
import { Header, Menu, Dropdown, Dimmer, Loader } from 'semantic-ui-react'
import Category from './menu/category.js';
import BasketStore from '../states/basket'
import BasketComponent from '../components/basket/basket'
import BasketBar from '../components/basket/basketBar'
import CatMenu from './headerComponents/catMenu'
import Footer from './footer'
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react'
import About from './about'
import ShopClosedModal from './shopClosedModal'
import StickyBox from "react-sticky-box"

const HomePage = () => {
  const [categories, setCategories] = useState(null);
  const [menu, setMenu] = useState(null);
  const [activeItem, setActiveItem] = useState("menu");
  const [forceClose, setForceClose] = useState(false);
  const [forceOpen, setForceOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setLoading(true);
    fetch("/api/getMenu", {
      method: "get",
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then((res) => {
        const catWithOrder = [... new Set(res.menu.filter((item) => item.displayOrder < 999).sort((a, b) => { return a.displayOrder - b.displayOrder }).map(item => item.category))];
        const catWithoutOrder = [... new Set(res.menu.filter((item) => item.displayOrder === 999).map(item => item.category))].sort((a, b) => { return a < b ? -1 : a > b ? 1 : 0 });
        const categories = [... new Set(catWithOrder.concat(catWithoutOrder))];
        setMenu(res.menu);
        setLoading(false);
        setCategories(categories);
        setForceClose(res.config[0].forceClose);
        setForceOpen(res.config[0].forceOpen);
      })
  }, []);

  const quickAdd = (e, { value }) => {
    document.getElementById(value).click();
    setSearch("");
  }
  const displayMenu = () => {
    if (categories) {
      return <>
        <Dropdown
          className={css(styles.search)}
          placeholder='Search for your favourite dishes'
          search
          selection
          value={search}
          options={menu.map(i => ({ key: i.itemName, value: i.itemName, text: i.itemName }))}
          onChange={quickAdd}
        />
        {categories.map((cat) => <Category key={cat} name={cat} menu={menu} basket={BasketStore} />)}
      </>
    }
  }

  const navMenu = <Menu fluid widths={2} pointing secondary color="blue">
    <Menu.Item name='Menu' active={activeItem === "menu"} onClick={() => setActiveItem("menu")}>
      <Header>Menu</Header>
    </Menu.Item>

    <Menu.Item name='about' active={activeItem === "about"} onClick={() => setActiveItem("about")}>
      <Header>Contact us</Header>
    </Menu.Item>
  </Menu>

  return (
    <div>
      <NavBar />
      <ShopClosedModal basket={BasketStore} forceClose={forceClose} forceOpen={forceOpen} />
      <div className={css(styles.nav)}>
        {navMenu}
      </div>
      <CatMenu categories={categories} hide={activeItem !== "menu"} />
      <div className={css(styles.container)}>
        <div className={css(styles.menu, activeItem !== "menu" && styles.hide)} >
          <Dimmer active={loading} inverted>
            <Loader size='large'></Loader>
          </Dimmer>
          {displayMenu()}
        </div>
        <div>
          <About hide={activeItem === "menu"} />
        </div>
        <StickyBox offsetTop={50} offsetBottom={20}>
          <BasketComponent basket={BasketStore} />
        </StickyBox>
        <div className={css(styles.basketBar)}>
          {BasketStore.numOfItems !== 0 && <BasketBar basket={BasketStore} />}
        </div>
      </div>
      <div className={css(styles.footer)}>
        <Footer />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    background: "#f5f5f5",
    alignItems: "flex-start"
  },
  basketBar: {
    '@media (min-width: 1024px)': {
      display: 'none',
    },
  },
  menu: {
    '@media (max-width: 767px)': {
      flexGrow: 1,
      paddingBottom: "50px",
    },
    marginBottom: "20px",
  },
  nav: {
    '@media (min-width: 768px)': {
      width: "455px",
    },
    margin: "auto",
    paddingTop: "10px",
  },
  hide: {
    display: 'none'
  },
  search: {
    marginTop: "10px",
    width: "98%"
  }
});
export default observer(HomePage);