import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import logo from '../../img/logo.png';

const NavBar = () => {
  return (
    <div className={css(styles.borderB)}>
      <img src={logo} className={css(styles.logoImage)} onClick={() => window.location.href = "/"}></img>
    </div>
  )
}

const styles = StyleSheet.create({
  lableText: {
    fontSize: "20px"
  },
  positonLeft: {
    justifyContent: "flex-start",
    paddingLeft: "40px"
  },
  noUser: {
    paddingTop: "50px",
  },
  picBox: {
    width: "45px",
    height: "45px"
  },
  hoverImage: {
    width: '36px',
    height: '36px',
    ':hover': {
      width: '45px',
      height: '45px'
    }
  },
  logoImage: {
    width: '280px',
    height: '62px',
    margin: "20px",
    cursor: "pointer"
  },
  positionRight: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "30px",
    paddingRight: "20px",
    height: "70px"
  },
  divider: {
    maxWidth: "1200px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",

  },
  borderB: {
    borderBottom: "3px solid #4874CB",
    '@media (min-width: 800px)': {
      display: "flex",
    },
  },
  userText: {
    paddingRight: "18px",
    paddingTop: "15px"
  }
})

export default NavBar;