import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Header, Icon } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite';

const Footer = () => {
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.section)}>
        <Header>Get in touch</Header>
        <p><Icon name='location arrow' /><b>Address:</b> 15 Downham Road, Birkenhead, CH42 5NJ</p>
        <p><Icon name='phone' /><b>Telephone Number:</b> 0151 203 9988</p>
        <p><Icon name='mail' /><b>Email:</b> thefishinn.birkenhead@gmail.com</p>
        <Header>Payment Methods</Header>
        <Icon size='big' name='cc mastercard' />
        <Icon size='big' name='cc visa' />
      </div>
      <div className={css(styles.section)}>
        <Header>Opening Hours</Header>
        <p><b>Open on all Bank Holidays</b></p>
        <p><b>Monday:</b> Closed</p>
        <p><b>Tuesday:</b> 16:30 - 22:30</p>
        <p><b>Wednesday:</b> 16:30 - 22:30</p>
        <p><b>Thursday :</b> 16:30 - 22:30</p>
        <p><b>Friday:</b> 16:30 - 23:00</p>
        <p><b>Saturday:</b> 16:30 - 23:00</p>
        <p><b>Sunday:</b> 16:30 - 22:00</p>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    margin: "auto",
    padding: "50px",
    flexWrap: "wrap",
    minHeight: "400px",
    background: "#eaeaea",
  },
  section: {
    width: "250px",
    margin: "auto",
    paddingBottom: "30px",
    marginTop: "0px",
    textAlign: "left"
  }
});
export default Footer;